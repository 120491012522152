<script >
import Icons from "../../components/reuseables/Icons.vue";
import {mapGetters} from "vuex";
import Camera from "../../components/reuseables/Camera.vue";
import {upgradeKudaAccount, uploadToCloudinary} from "../../services/api/APIService";
import BaseButton from "../../components/reuseables/BaseButton.vue";

export default {
  name: "UpdateAccount",
  components: {BaseButton, Camera, Icons},
  data(){
    return{
      accountDetails:{},
      loading:false
    }
  },
  computed:{
    ...mapGetters("paddler", ["logInPaddler"]),
  },
  methods:{
    async getPickedImage(pickedImage){
      if (pickedImage){
        this.accountDetails.faceImageUrl = pickedImage
      }
    },
    async updateAccount(){
      this.loading = true
      if (this.logInPaddler.profileImage && this.accountDetails.bvn &&
          this.accountDetails.bvn.length === 11 &&
          this.accountDetails.nin && this.accountDetails.nin.length === 11){
          this.accountDetails.faceImageUrl = this.logInPaddler.profileImage
        this.accountDetails.email = this.logInPaddler.email
        upgradeKudaAccount(this.accountDetails).then(() =>{
          this.$displaySnackbar({
            message: "Account upgrade was successful",
            success: true,
          });
          this.$router.push({name: "Wallet"})
          window.location.reload()
        }).catch(err =>{
          this.loading = false
          console.log(err)
          this.$displaySnackbar({
            message: err.message,
            success: false,
          });
        }).finally(()=>
            this.loading = false)
      }
      else if (this.accountDetails.faceImageUrl && this.accountDetails.bvn &&
          this.accountDetails.bvn.length === 11 &&
          this.accountDetails.nin && this.accountDetails.nin.length === 11){
        let newData = {
          file: this.accountDetails.faceImageUrl,
          upload_preset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
        }
        const response = await uploadToCloudinary(newData)
        this.accountDetails.faceImageUrl = response.data.url
        this.accountDetails.email = this.logInPaddler.email
        upgradeKudaAccount(this.accountDetails).then(() =>{
          this.$displaySnackbar({
            message: "Account upgrade was successful",
            success: true,
          });
          this.$router.push({name: "Wallet"})
          window.location.reload()
        }).catch(err =>{
          this.loading = false
          console.log(err)
          this.$displaySnackbar({
            message: err.message,
            success: false,
          });
        }).finally(()=>
        this.loading = false)
      }
      else {
        this.loading = false
        this.$displaySnackbar({
          message: "Invalid input data, try again",
          success: false,
        });
      }
    }
  }
}
</script>

<template>
<div class="tw-flex tw-w-full tw-flex-col tw-px-5 tw-pt-5 lg:tw-pt-0">
  <div class="tw-cursor-pointer" @click="$router.back()">
    <Icons color="#004AAD" name="arrow-back"></Icons>
  </div>
    <div class="tw-flex tw-w-full tw-justify-center tw-item-center tw-mt-20">
        <div class="update-card tw-flex tw-flex-col tw-p-5 lg:tw-p-10 tw-mx-5 lg:mx-0">
          <h6 class="update-card-header tw-mb-5" :style="{color: '#004AAD'}">Update Account</h6>
          <p class="form-header">Image matching NIN</p>
          <div class="camera-div">
            <camera :existing-image="logInPaddler.profileImage" @pickedImage="getPickedImage" company-color="#004AAD"></camera>
          </div>
            <p class="form-header">NIN (National Identification Number)</p>
            <ValidationProvider name="NIN" rules="required" v-slot="{classes, errors}">
              <div :class="classes">
                <v-text-field solo placeholder="22221021265" type="text" color="#004aad" hide-details
                              v-model="accountDetails.nin"></v-text-field>
                <span> {{errors[0]}} </span>
              </div>
            </ValidationProvider>
            <div class="tw-py-5">
            <p class="form-header">BVN (Bank Verification Number)</p>
            <ValidationProvider name="BVN" rules="required" v-slot="{classes, errors}">
              <div :class="classes">
                <v-text-field solo placeholder="10112100101" type="text" color="#004aad" hide-details
                              v-model="accountDetails.bvn" ></v-text-field>
                <span> {{errors[0]}} </span>
              </div>
            </ValidationProvider>
            </div>
          <base-button button-text="Update" color="#004AAD"
                       @click="updateAccount" :loading="loading"></base-button>
        </div>
    </div>
</div>
</template>

<style scoped lang="scss">
.update-card{
  min-height: 700px;
  width: 700px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  background: white;
  height: max-content;

  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.update-card-header{
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  text-transform: capitalize;
  color: black;
  line-height: 10px;
  font-weight: bold;
}
.camera-div{
  width: 100%;
  height: 350px;
  @media screen and (max-width:768px){
    height: 400px;
  }
}
</style>