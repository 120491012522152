<script >
 import dayjs from "dayjs";
 // import {uploadToCloudinary} from "../../services/api/APIService";

 export default {
   name: "Camera",
   props:{
     existingImage : null,
     companyColor: {
       type: [String],
       default: "#004AAD"
     }
   },
   data() {
     return {
       ctx: null,
       currentImage:null,
       imageOption: "snap",
       restartCamera : false,
       disableSnap: true
     }
   },
   watch:{
     imageOption:{
       handler: function(val){
         if (val && val === 'snap'){
           this.currentImage = null
         }
         else if (val && val === 'upload' && !this.disableSnap){
           this.currentImage = null
           this.stopCamera()
         }
       },
       immediate :true
     },
     existingImage:{
       handler: function (val){
         if (val){
           this.currentImage = val
           this.imageOption = "upload"
         }
       },
       immediate:true
     }
   },
   methods: {
     async startCamera() {
       this.disableSnap = false
       this.currentImage = null
       const video = this.$refs.video;
       const canvas = this.$refs.canvas;
       this.ctx = canvas.getContext('2d');

       try {
         video.srcObject = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
         await video.play()
         requestAnimationFrame(this.draw)
         // Detect faces in the video stream
       } catch (error) {
         console.error('Error accessing camera:', error);
       }
       },
       draw(){
         this.ctx.drawImage(this.$refs.video,0,0,this.$refs.canvas.width,this.$refs.canvas.height)
         requestAnimationFrame(this.draw)
       },
      async takePicture() {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let imageName = "userOnboarding"
        if (userData) {
          imageName = userData.email.replace("@")
        }
        let link = document.createElement('a')
        link.download = imageName + `${dayjs().toISOString()}.png`
        link.href = this.$refs.canvas.toDataURL()
        this.currentImage = this.$refs.canvas.toDataURL()
        if (this.currentImage){
          this.$emit('pickedImage', this.currentImage)
        }
        this.restartCamera = true
        this.stopCamera()
        // link.click()
      },
     stopCamera() {
       const video = this.$refs.video;
       const stream = video.srcObject;
       if (stream) {
         const tracks = stream.getTracks();
         tracks.forEach(track => track.stop());
         video.srcObject = null;
       }
     },
     handleRestartCamera(){
       this.disableSnap = true
       this.restartCamera = false
       this.currentImage = null
     },
     onUserImageClick() {
       this.$refs.userImage.click()
     },
     onUserImagePicked(event) {
       const files = event.target.files[0]
       try {
         const fileReader =  new FileReader()
         fileReader.addEventListener('load', async () => {
           this.currentImage = fileReader.result
           if (this.currentImage){
             this.$emit('pickedImage', this.currentImage)
           }
         })
         fileReader.readAsDataURL(files)

       } catch (error) {
         console.log(error.response);
       }

     },

     }

 }
</script>

<template>
 <div class="tw-flex tw-w-full tw-flex-col">

   <div v-if="imageOption === 'snap'">
     <video ref="video" autoplay playsinline muted hidden ></video>
     <img :src="currentImage" v-if="currentImage" alt="current image" class="tw-cursor-pointer image upload-picture">
     <canvas v-else  ref="canvas" width="300" height="220" class="snap-picture tw-rounded-3xl tw-cursor-pointer" @click="startCamera" ></canvas>
     <div class="tw-py-4">
       <v-btn v-if="restartCamera"  @click="handleRestartCamera" :color="companyColor" class="snap-btn">Retake</v-btn>
       <v-btn v-else  @click="takePicture" :color="companyColor" class="snap-btn" :disabled="disableSnap">Snap</v-btn>
     </div>
   </div>
   <div v-else>
       <div class="upload-picture tw-cursor-pointer" @click="onUserImageClick">
         <img :src="currentImage" alt="" v-if="currentImage" class="upload-picture" >
         <img src="@/assets/plus.svg" alt="" v-else>
       </div>
       <input type="file" ref="userImage" accept="image/*" style="display:none" @change="onUserImagePicked">
     </div>
   <v-radio-group v-model="imageOption"  :row="$vuetify.breakpoint.mdAndUp">
     <v-radio value="snap" label="Take a Picture" :color="companyColor"></v-radio>
     <v-radio value="upload" label="Upload an Image" :color="companyColor"></v-radio>
   </v-radio-group>
 </div>

</template>

<style scoped lang="scss">
.snap-picture{
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/camera.png");
  border: 1px solid rgba(79,79,79,0.5);
}
.upload-picture{
  display: flex;
  border: 1px solid rgba(79,79,79,0.5);
  height: 220px;
  width: 300px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;

  @media screen and (max-width: 768px){
    width: 100%;
  }
}
.image{
  display: flex;
  border-radius: 10px;
}
.snap-btn{
  width: 300px;
  text-transform: capitalize !important;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  color: white;
}

</style>